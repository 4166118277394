import React from 'react'
import SlickSlider from 'react-slick'
import * as S from './styles'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return <div className={className} style={{ ...style }} onClick={onClick} />
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return <div className={className} style={{ ...style }} onClick={onClick} />
}

const defaultSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 7,
  slidesToScroll: 2,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  centerMode: true,
  className: 'center',
  centerPadding: '0px',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const Slider = ({ register, children, settings, casesSettings, depositionsSettings }) => (
  <S.Wrapper>
    <SlickSlider
      ref={register}
      {...defaultSettings}
      {...settings}
      {...casesSettings}
      {...depositionsSettings}
    >
      {children}
    </SlickSlider>
  </S.Wrapper>
)

export default Slider
